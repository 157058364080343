import MockAdapter from 'axios-mock-adapter'

import {MockPermissions} from './MockPermissions'
import {MockRoles} from './MockRoles'
import {AppStage, MockStorage} from './MockStorage'
import {MockUserRegistry} from './MockUserRegistry'

export class MockStore<TScenarioData = unknown> {
  public scenario(): TScenarioData {
    return this.storage.load().scenario as TScenarioData
  }

  public readonly permissions: MockPermissions
  public readonly roles: MockRoles

  public get users() {
    return this.usersInternal
  }
  private readonly usersInternal: MockUserRegistry

  public readonly DATA_STORAGE_KEY = 'mockstate'

  private readonly storage: MockStorage
  private readonly appStage: AppStage | undefined
  private readonly appName: string
  private onEnableCallbacks: (() => unknown)[] = []
  private onDisableCallbacks: (() => unknown)[] = []

  private constructor(appName: string, appStage: AppStage) {
    this.appName = appName
    this.appStage = appStage
    this.storage = new MockStorage(this.DATA_STORAGE_KEY)
    this.permissions = new MockPermissions(this.storage)
    this.roles = new MockRoles(this.storage)
    this.usersInternal = new MockUserRegistry(this.storage, this.appName, this.appStage)
  }

  /**
   * Main entry point to create a mockStore singleton or enable mocking in your browser
   */
  static enableMocking<TScenarioData>(appName: string, appStage: AppStage) {
    const store = new MockStore<TScenarioData>(appName, appStage)

    if (typeof window !== 'undefined') {
      // eslint-disable-next-line
      ;(window as any).mockStore = store
    }

    return store
  }

  setScenarioData<TScenarioData>(data: TScenarioData) {
    const current = this.storage.load()
    current.scenario = data as Record<string, unknown> | undefined
    this.storage.save(current)
  }

  clear() {
    this.reset()
    this.disable()
  }

  reset() {
    this.storage.reset()
  }

  enable() {
    const current = this.storage.load()
    current.mockEnabled = true
    this.storage.save(current)
    this.onEnableCallbacks.forEach((fn) => fn())
    this.onEnableCallbacks.splice(0, this.onEnableCallbacks.length)
  }

  onEnable(fn: () => unknown) {
    this.onEnableCallbacks.push(fn)
  }

  disable() {
    const current = this.storage.load()
    current.mockEnabled = false
    this.storage.save(current)
    this.onDisableCallbacks.forEach((fn) => fn())
    this.onDisableCallbacks.splice(0, this.onDisableCallbacks.length)
  }

  onDisable(fn: () => unknown) {
    this.onDisableCallbacks.push(fn)
  }

  isEnabled() {
    return this.storage.load().mockEnabled
  }

  enableDfApiMockForAxios(mockAdapter: MockAdapter) {
    this.users.registerDfResponseForAxios(mockAdapter)
    this.permissions.registerDfResponseForAxios(mockAdapter)
    this.roles.registerDfResponseForAxios(mockAdapter)
  }
}
