import {User} from '@hconnect/apiclient'
import MockAdapter from 'axios-mock-adapter'
import {v4 as uuidv4} from 'uuid'

import type {AppStage, MockStorage} from './MockStorage'

export class MockUserRegistry {
  private readonly tokenStorageKey: string

  constructor(
    private storage: MockStorage,
    appName: string,
    appStage: AppStage
  ) {
    this.tokenStorageKey = `HC-${appName}-${appStage}`
  }

  public addUser(userOverride: Partial<User> = {}) {
    const userId: string = userOverride.id || userOverride.user_id || uuidv4()
    const defaultUser: User = {
      // Added user_id property because of changes in User model
      user_id: userId,
      requestedCustomerNumber: null,
      changePasswordAtNextLogin: false,
      companyName: 'Test',
      country: 'DE',
      createdBy: '',
      createdOn: '2020-01-01',
      creationChannel: 'portalRegistration',
      defaultBranding: 'de.heidelbergerbeton',
      defaultLocale: 'de-DE',
      eMail: 'testuser@example.com',
      firstName: null,
      hasRoles: [
        'MANAGING_DIRECTOR',
        'PLANT_DIRECTOR',
        'PRODUCTION_SUPERVISOR',
        'ENERGY_MANAGER',
        'CONTROL_ROOM_OPERATOR'
      ],
      id: userId,
      isActive: true,
      isAnalyticsEnabled: false,
      isBlocked: false,
      isEmailVerified: false,
      isInternal: true,
      isMobileNumberVerified: false,
      isPasswordChangeRequired: false,
      isTermsApprovalRequired: true,
      isTester: true,
      jobTitle: null,
      lastLoginDate: '2020-11-27',
      lastName: null,
      mobileDeviceToken: null,
      mobileDeviceTokens: [],
      mobileNumber: '+4915253381842',
      modifiedBy: '39fa6793-fc38-4c6e-b744-2020c58b7160',
      modifiedOn: '2020-07-30',
      name: 'Test User',
      primaryOrganisationalUnit: 'de.DE35',
      region: null,
      subscriptions: null,
      username: 'testuser@example.com',
      marketId: 'de.DE35',
      creationProduct: 'HConnect',
      isPushNotificationsEnabled: false,
      isInvited: true,
      isLockedOut: false,
      failedLogin: false
    } as unknown as User
    const userToAdd: User = {...defaultUser, ...userOverride}
    this.write([...this.current(), userToAdd])
    return userToAdd
  }

  public updateUser(updatedUser: User) {
    const users = this.current().map((u) => (u.id === updatedUser.id ? updatedUser : u))
    this.write(users)
  }

  public login(userId: string | {id: string}) {
    const id = typeof userId === 'string' ? userId : userId.id
    const tokens = this.getTokens(id)
    const serializedAccessToken = 'header.' + tokens.accessToken + '.signature'
    const serialized = JSON.stringify({
      accessToken: serializedAccessToken,
      refreshToken: tokens.refreshToken
    })
    localStorage.setItem(this.tokenStorageKey, serialized)
  }

  public logout() {
    localStorage.removeItem(this.tokenStorageKey)
  }

  /**
   *
   * Returns an (invalid) JWT encoded as a string. It is invalid since we do not apply valid
   * signature using cryptography (but jwt-decode will not check this anyways)
   */
  public getTokens(
    userId: string,
    tokenOverride?: Record<string, unknown>
  ): {accessToken: string; refreshToken: string} {
    const now = new Date()
    const user = this.getUser(userId)
    if (!user) throw new Error('Unknown user')
    now.setDate(now.getDate() + 1)
    const accessToken = btoa(
      JSON.stringify({
        nbf: now.getTime(),
        exp: now.getTime(),
        iss: 'https://api-qa.hconnect.heidelbergcement.com/identity',
        aud: 'global',
        client_id: 'HConnect',
        sub: uuidv4(),
        auth_time: now.getTime(),
        idp: 'local',
        given_name: user.name,
        user_id: user.id,
        email: user.eMail,
        isPasswordChangeRequired: false,
        role: 'internal',
        isTestUser: 'true',
        scope: ['openid', 'global', 'offline_access'],
        amr: ['pwd'],
        ...tokenOverride
      })
    )
    const refreshToken = btoa('somerandomstring')
    return {accessToken, refreshToken}
  }

  public getAllUsers(): User[] {
    return this.current()
  }

  public getUser(userId: string): User | undefined {
    return this.current().find((u) => u.id === userId)
  }

  public registerDfResponseForAxios(mockAdapter: MockAdapter) {
    mockAdapter.onGet(/\/users\/.*/).reply((req) => {
      if (!req.url) return [500, undefined]
      const userId = req.url.split('users/')[1]
      try {
        const user = this.getUser(userId)
        return [200, user]
      } catch {
        return [404, undefined]
      }
    })
  }

  private write(users: User[]) {
    const current = this.storage.load()
    current.users = users
    this.storage.save(current)
  }

  private current(): User[] {
    return this.storage.load().users || []
  }
}
