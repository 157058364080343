import {Permission, User} from '@hconnect/apiclient'
import {AppStage, MockStore} from '@hconnect/common/mocking'

import {MockState} from '../../src/__mock__/mockState'

import {sampleDataset01} from './sampleDataset'
import assetsPermissions from './sampleDatasets/01/permissions/assetsPermissions.json'
import commentPermissions from './sampleDatasets/01/permissions/commentsPermissions.json'
import demandPermissions from './sampleDatasets/01/permissions/demandPermissions.json'
import documentPermissions from './sampleDatasets/01/permissions/documentPermissions.json'
import electricityPricePermissions from './sampleDatasets/01/permissions/electricityPricePermissions.json'
import machinePlanPermissions from './sampleDatasets/01/permissions/machinePlanPermissions.json'
import materialOrdersPermissions from './sampleDatasets/01/permissions/materialOrdersPermissions.json'
import materialsPermissions from './sampleDatasets/01/permissions/materialsPermissions.json'
import storagePermissions from './sampleDatasets/01/permissions/storagePermissions.json'
/**
 * Burglengenfeld plant code for cypress tests
 */
export const TEST_PLANT_CODE = '0014'
// test user id should match user id in permissions
export const TEST_USER_ID = '7af98e9f-44a9-49db-9ad5-2e43a8c0128c'

export const defaultPermissions: Permission[] = [
  ...(commentPermissions as Permission[]),
  ...(machinePlanPermissions as Permission[]),
  ...(materialsPermissions as Permission[]),
  ...(assetsPermissions as Permission[]),
  ...(storagePermissions as Permission[]),
  ...(materialOrdersPermissions as Permission[]),
  ...(electricityPricePermissions as Permission[]),
  ...(demandPermissions as Permission[]),
  ...(documentPermissions as Permission[])
]

export const mockStore = MockStore.enableMocking<MockState>(
  'HCEM',
  process.env.REACT_APP_STAGE as AppStage
)

mockStore.onEnable(() => {
  mockStore.setScenarioData(sampleDataset01)
  const testUser: User = mockStore.users.addUser({name: 'Test User', id: TEST_USER_ID})
  mockStore.users.login(testUser)
  defaultPermissions.map((permission) =>
    mockStore.permissions.addPermission(permission.userId, permission.permissionType, {
      dataScope: permission.dataScope
    })
  )
})
