import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableKpiEndpoints = (mock: MockAdapter) => {
  // GET history assets
  mock
    .onGet(new RegExp(`^/plants/${numberRegEx}/schedules/kpi/asset-capacity$`))
    .reply((config) => {
      const {from} = config.params as {from: string; to: string}
      const {
        burglengenfeld: {
          assetsCapacityByMonth,
          plantConfig: {timezone_id: timezoneId}
        }
      } = mockStore.scenario()

      const month = moment.utc(from).tz(timezoneId).format('MM')

      return sleepResponse([200, assetsCapacityByMonth[month]])
    })

  mock.onGet(new RegExp(`^/plants/${numberRegEx}/schedules/optimized`)).reply(() => {
    const {
      burglengenfeld: {optimizedSchedules}
    } = mockStore.scenario()

    return sleepResponse([200, optimizedSchedules])
  })

  mock
    .onPatch(new RegExp(`^/plants/${numberRegEx}/schedules/optimized/${numberRegEx}`))
    .reply((config) => {
      const scheduleId = parseInt((config.url as string).split('/')[5])

      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {optimizedSchedules}
      } = scenario

      const optimizedSchedule = optimizedSchedules.find((e) => e.scheduleId === scheduleId)

      if (!optimizedSchedule) {
        return sleepResponse([500, {}])
      }

      scenario.burglengenfeld.schedule = {...scenario.burglengenfeld.schedule, ...optimizedSchedule}

      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  return mock
}
